import {
  AccountSummary,
  Account,
  Instrument,
  TimeseriesBalance,
  Transaction,
  Timeseries,
  TimeseriesPlotlyDatasets,
  PlotlyDataset,
  CategoryBreakdown,
} from "../models";

const adaptAccountSummary = (acc: any): AccountSummary => {
  return {
    totals: {
      netWorth: Number(acc.totals.net_worth),
      cash: Number(acc.totals.cash),
      cashWeighting: Number(acc.totals.cash_weighting),
      stocks: Number(acc.totals.stocks),
      stocksWeighting: Number(acc.totals.stocks_weighting),
      cryptocurrencies: Number(acc.totals.cryptocurrencies),
      cryptocurrenciesWeighting: Number(acc.totals.cryptocurrencies_weighting),
    },
    accounts: acc.accounts.map(adaptAccount),
    instruments: acc.instruments.map(adaptInstrument),
  };
};

const adaptAccount = (acc: any): Account => {
  return {
    accountID: acc.account_id,
    accountName: acc.account_name,
    accountNumber: acc.account_number,
    availableBalance: Number(acc.available_balance),
    currentBalance: Number(acc.current_balance),
    transactionSum: Number(acc.transaction_sum),
    bank: acc.bank,
    type: acc.type,
    netWeight: Number(acc.net_weight),
    typeWeight: Number(acc.type_weight),
  };
};

const adaptInstrument = (inst: any): Instrument => {
  return {
    instrumentID: inst.instrument_id,
    institution: inst.institution,
    name: inst.name,
    assetType: inst.asset_type,
    lastCloseDate: inst.last_close_date,
    units: Number(inst.units),
    unitPrice: Number(inst.unit_price),
    value: Number(inst.value),
    netWeight: Number(inst.net_weight),
    typeWeight: Number(inst.type_weight),
    realisedGains: Number(inst.realised_gains),
    unrealisedGains: Number(inst.unrealised_gains),
  };
};

const adaptTSBalance = (tsBalances: any): TimeseriesBalance[] => {
  return tsBalances.map(({ date, balance }: any) => {
    return {
      balance: Number(balance),
      date: new Date(date),
    };
  });
};

const adaptTimeseries = (t: any): Timeseries => {
  return {
    accounts: t.accounts.map((ts: any) => {
      return {
        accountID: ts.account_id,
        label: ts.name,
        assetType: ts.asset_type,
        timeseriesBalances: adaptTSBalance(ts.timeseries_balance),
      };
    }),
    total: adaptTSBalance(t.total),
    cashTotal: adaptTSBalance(t.cash),
    stocksTotal: adaptTSBalance(t.stocks),
    cryptocurrenciesTotal: adaptTSBalance(t.cryptocurrencies),
  };
};

const adaptPlotlyTimeseries = (timeseries: any): TimeseriesPlotlyDatasets => {
  const ts = adaptTimeseries(timeseries);

  const dateLabels = ts.total.map((balance) => balance.date);
  // dateLabels.reverse();

  const accountsByAssetType = {};

  ts.accounts.map((acc) => {
    if (!accountsByAssetType[acc.assetType]) {
      accountsByAssetType[acc.assetType] = [];
    }
    accountsByAssetType[acc.assetType].push(timeseriesToPlotly(
      acc.label,
      dateLabels,
      acc.timeseriesBalances,
      acc.accountID
    ));
    return null;
  });

  return {
    line: {
      accounts: Object.keys(accountsByAssetType).map((assetType) => {
        return {
          assetType,
          datasets: accountsByAssetType[assetType],
        };
      }),
      summaries: [
        timeseriesToPlotly("Net", dateLabels, ts.total, ""),
        timeseriesToPlotly("Cash", dateLabels, ts.cashTotal, ""),
        timeseriesToPlotly("Stocks", dateLabels, ts.stocksTotal, ""),
        timeseriesToPlotly("Crypto", dateLabels, ts.cryptocurrenciesTotal, ""),
      ],
    },
    stack: {
      summaries: [
        timeseriesToPlotlyStack("Cash", dateLabels, ts.cashTotal, ""),
        timeseriesToPlotlyStack("Stocks", dateLabels, ts.stocksTotal, ""),
        timeseriesToPlotlyStack(
          "Crypto",
          dateLabels,
          ts.cryptocurrenciesTotal,
          ""
        ),
      ],
    },
  };
};

const timeseriesToPlotly = (
  label: string,
  dateLabels: Date[],
  ts: TimeseriesBalance[],
  id: string
): PlotlyDataset => {
  const values = ts.map((tsb) => tsb.balance);
  //values.reverse();

  /* tslint:disable */
  const color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);
  /* tslint:enable */

  return {
    id,
    y: values,
    x: dateLabels,
    name: label,
    type: "scatter",
    mode: "lines+points",
    marker: { color },
  };
};

const timeseriesToPlotlyStack = (
  label: string,
  dateLabels: Date[],
  ts: TimeseriesBalance[],
  id: string
): PlotlyDataset => {
  const values = ts.map((tsb) => tsb.balance);
  values.reverse();

  /* tslint:disable */
  const color = "#" + (((1 << 24) * Math.random()) | 0).toString(16);
  /* tslint:enable */

  return {
    id,
    y: values,
    x: dateLabels,
    name: label,
    type: "bar",
    mode: "",
    marker: { color },
  };
};

const adaptTransaction = (tx: any): Transaction => {
  const result: Transaction = {
    transactionID: tx.transaction_id,
    date: tx.date,
    description: tx.description,
    amount: tx.amount,
    status: tx.status,
    accountNumber: tx.account_number,
    accountName: tx.account_name,
    bank: tx.bank,
    createdAt: new Date(tx.created_at),
  };
  if (tx.settled_at) {
    result.settledAt = new Date(tx.settled_at);
  }

  if (tx.metadata) {
    result.metadata = {
      description: tx.metadata.description,
      message: tx.metadata.message,
      referenceID: tx.metadata.reference_id,
      category: tx.metadata.category,
    };
  }

  return result;
};

const adaptCategoryBreakdown = (b: any): CategoryBreakdown => {
  const result: CategoryBreakdown = {
    category: {
      id: b.category.id,
      name: b.category.name,
    },
    creditAmount: b.credit_amount,
    credits: b.credits,
    debitAmount: b.debit_amount,
    debits: b.debits,
    totalAmount: b.total_amount,
  };
  return result;
};

const e = {
  adaptPlotlyTimeseries,
  adaptTransaction,
  adaptAccountSummary,
  adaptCategoryBreakdown,
};
export default e;
